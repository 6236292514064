@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'custom';

:root {
    --ui-dark: #3B4759;
    --ui-white: #fff;
    --ui-gray: #9CA3AF;
    --ui-primary: #0278D6;
    --ui-green: #3AAB82;
    --ui-error: #ff7875;
    --ui-light-gray: #f0f0f0;
    --ui-danger: #E73C37;
}

// disabled layout shift when page scrollbar is hide
html {
    scrollbar-gutter: stable;
}

body {
    font-family: var(--font-primary), var(--font-secondary);
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    padding: 0;
    width: 100% !important;
}

.title-gradient {
    background: linear-gradient(90deg, #02857F 14.25%, #0278D6 90.57%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.secondary-gradient {
    background: linear-gradient(90deg, #02857F 18.80%, #0278D6 75.58%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.tertiary-gradient {
    background: linear-gradient(90deg, #02857F 20.39%, #0278D6 81.94%) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.third-gradient {
    background: linear-gradient(90deg, #02857F 20.51%, #F1B359 58.81%, #0278D6 96.07%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.input-gray {
    @apply bg-zinc-50 border-zinc-50 #{!important};
    border-width: 1.5px;

    input {
        @apply bg-zinc-50;
    }
}

.link {
    @apply text-primary;

    &:hover {
        @apply text-blue-400;
    }
}

.flex-center {
    @apply flex items-center justify-center;
}


.job-title:hover {
    background: linear-gradient(90deg, #02857F 14.25%, #0278D6 90.57%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.content-body {
    min-height: calc(100vh - 280px);
}

.ui-select {

    &:hover,
    &:focus {
        @apply border-primary;
        box-shadow: 0 0 0 2px rgba(5, 185, 255, 0.1);
        outline: 0;
    }
}

// custom slider
.main-slider {
    .slick-list {
        padding-left: 0px !important;
    }

    .slick-slide {
        pointer-events: auto !important;
    }

    @media only screen and (max-width: 1023px) {
        .slick-slide {
            .card-slide {
                padding-left: 32px;
                padding-right: 12px;
            }
        }

        .slick-active {
            .card-slide {
                padding-left: 12px;
                padding-right: 12px !important;
            }
        }
    }
}

.card-slide {
    padding-top: 10px;
    padding-bottom: 10px;

    @screen xs {
        width: 313px;
    }

    @screen md {
        padding-left: 14px;
        padding-right: 14px;
        width: 100% !important;
    }
}

.layout-chat {
    height: calc(100svh - 72px);
}

.two-line-text {
    width: 200px;
    /* Adjust the width as needed */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /* Number of lines to display */
    -webkit-box-orient: vertical;
}

.medimatch-btn-primary {
    background-color: #0278d6;
}

.medimatch-picker-time-panel-column {
    width: 120px !important;
}

.medimatch-picker-time-panel-cell-inner {
    padding: 0px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100px !important;
}

.noFooterTimePick .medimatch-picker-footer {
    display: none !important;
}

body:has(.medimatch-modal-root) {
    overflow-y: auto !important;
    // overflow-x: hidden !important;
}

body:has(.ratingWindow) {
    overflow-y: hidden !important;
}