$primary-gradient: linear-gradient(90deg, #02857F 0%, #0278D6 108.42%);

.btn {
    border-radius: 12px;
    font-weight: 500 !important;
    padding: 8px 24px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.btnLarge {
    height: 58px !important;
    padding: 6px 14px !important;
}

.btnMiddle {
    font-size: 1.125rem !important;
    height: 48px !important;
}

.btnShadow {
    box-shadow: -1px 6px 17px -4px rgba(2, 120, 214, 0.3) !important;
    -webkit-box-shadow: -1px 6px 17px -4px rgba(2, 120, 214, 0.3) !important;
    -moz-box-shadow: -1px 6px 17px -4px rgba(2, 120, 214, 0.3) !important;
}

.btnDefault {
    background-color: var(--ui-white) !important;
    border: 1.5px solid #E9E9E9 !important;
    color: var(--ui-gray) !important;
    font-weight: 400 !important;
    outline: none !important;
    box-shadow: none !important;

    &:hover {
        background: var(--ui-light-gray) !important;

        :global {
            .medimatch-btn-icon>svg>g {
                stroke: var(--ui-primary);
            }
        }
    }

    &:disabled {
        background: var(--ui-light-gray) !important;
        color: var(--ui-gray) !important;
        filter: brightness(1);
        border: none !important;

        :global {
            .medimatch-btn-icon>svg>g {
                stroke: var(--ui-gray) !important;
            }
        }
    }
}

.btnPrimary {
    background: $primary-gradient !important;
    border: none !important;
    color: var(--ui-white) !important;

    &:hover {
        color: var(--ui-white) !important;
        filter: brightness(0.9);
    }

    &:disabled {
        background: var(--ui-light-gray) !important;
        color: var(--ui-gray) !important;
        filter: brightness(1);
    }

    :global {
        span.medimatch-btn-icon {
            margin-inline-end: 0 !important;
        }
    }
}

.btnGhost {
    background-image: $primary-gradient !important;
    background-origin: border-box !important;
    box-shadow: inset 0 100vw var(--ui-white) !important;
    border: 2px solid transparent !important;

    &>span {
        background: linear-gradient(90deg, #02857F 20.39%, #0278D6 81.94%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }

    &:hover {
        background: $primary-gradient !important;
        border: none;
        box-shadow: none !important;

        &>span {
            background: var(--ui-white);
            background-clip: text;
        }

        :global {
            .medimatch-btn-icon>svg>path {
                stroke: var(--ui-white) !important;
            }
        }
    }

    &:disabled {
        background: none !important;
        filter: brightness(1);
        border: 2px solid #E9E9E9 !important;

        &>span {
            background-clip: none;
            -webkit-background-clip: none;
            -webkit-text-fill-color: initial;
            color: var(--ui-light-gray) !important;
        }

        :global {
            .medimatch-btn-icon>svg>path {
                stroke: var(--ui-light-gray) !important;
            }
        }
    }
}

.btnLink {
    border: none !important;
    outline: none !important;

    &:hover {
        filter: brightness(1.1);
    }
}

.btnCustom {
    border-radius: 12px;
    font-weight: 500 !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
    outline: none !important;

    &:hover {
        filter: brightness(0.8);
    }
}