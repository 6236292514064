.medimatch {
    font-family: var(--font-primary), var(--font-secondary) !important;
}

.medimatch-card-head {
    border-bottom: none !important;
}

.medimatch-card-hoverable {
    &:hover {
        @apply border border-gray-100;
        box-shadow: 0px 4px 24px rgba(2, 120, 214, 0.10);
    }
}

.medimatch-card {
    border-radius: 30px !important;
}

.medimatch-picker-panel-container {
    border-radius: 8px !important;
}

.medimatch-select-dropdown {
    border-radius: 8px !important;
}

.medimatch-radio-inner {
    background-color: white !important;
}

.medimatch-radio-inner::after {
    background-color: #40506a !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Works for Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

// TO DO Form icon error

.medimatch-form-item-explain::before {
    content: "";
    background-image: url('../../public/assets/icons/icon-warning-filled.svg');
    background-size: 16px 16px;
    display: inline-block;
    width: 16px;
    height: 16px;
    color: red;
    left: 0;
    position: absolute;
}

.medimatch-form-item-explain {
    font-size: 12px !important;
    padding-left: 20px;
}

.hidden-error-form .medimatch-form-item-explain {
    display: none !important;
}

.medimatch-col.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;

    .medimatch-card .medimatch-card-head-title {
        white-space: pre-line !important;
    }
}

.medimatch-input-affix-wrapper {
    @apply rounded-lg;
}

.medimatch-input {
    @apply rounded-lg border-slate-50;

    &.medimatch-input-lg {
        @apply text-dark text-base;
        padding: 17px 12px !important;
        height: 58px;
    }

    &::placeholder {
        @apply text-base text-gray-400;
    }
}

.medimatch-input:hover,
.medimatch-input:focus {
    @apply border-primary;
}

.medimatch-input-password-icon {
    cursor: pointer;
}

.medimatch-input-affix-wrapper-lg {
    padding: 0px 18px;
    font-size: 16px;
    border-radius: 12px;
}

.medimatch-input-prefix {
    margin-inline-end: 0 !important;
}

.medimatch-checkbox-inner {
    border: 1.5px solid #3B4759 !important;
}

.medimatch-menu-horizontal {
    border-bottom: none !important;
}

.medimatch-menu-item::after {
    bottom: -12px !important;
    border-radius: 12px;
}


.medimatch-menu-item-selected {
    >span {
        background: linear-gradient(90deg, #02857F 20.39%, #0278D6 81.94%) !important;
        background-clip: text !important;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

    }
}

.medimatch-menu-item-selected::after {
    border-bottom-color: linear-gradient(90deg, #02857F 14.25%, #0278D6 90.57%) !important;
}

.medimatch-carousel .slick-next,
.medimatch-carousel .slick-prev {
    width: 32px;
    height: 32px;
}

.medimatch-dropdown-menu {
    border-radius: 12px !important;
    box-shadow: 8.66007px 3.60836px 48px 0px rgba(0, 0, 0, 0.06) !important;
}

.medimatch-select-clear {
    width: 20px !important;
    height: 20px !important;
    top: 42% !important;
    opacity: 1 !important;
}

.medimatch-select-single {
    height: 42px;
}

.medimatch-select-single.medimatch-select-lg {
    height: 48px;

    .medimatch-select-selector {
        @apply rounded-lg;
    }

    .medimatch-select-selection-item {
        font-size: 1rem !important;
    }

    .medimatch-select-selection-placeholder {
        @apply text-base text-gray-400;
    }
}

.medimatch-select-single.medimatch-select-lg:not(.medimatch-select-customize-input) .medimatch-select-selector .medimatch-select-selection-search-input {
    font-size: 16px;
}

.medimatch-notification-notice-description {
    white-space: break-spaces;
}

// stepper 
.medimatch-steps-vertical.medimatch-steps-dot .medimatch-steps-item-process .medimatch-steps-item-icon {
    margin-top: 8.375px !important;
    inset-inline-start: -5.125px !important;
}

.medimatch-steps-item-icon {
    width: 12px !important;
    height: 20px !important;
}

.medimatch-steps.medimatch-steps-vertical.medimatch-steps-dot .medimatch-steps-item-icon {
    margin-inline-start: -1px;
}

.medimatch-steps .medimatch-steps-item-finish>.medimatch-steps-item-container>.medimatch-steps-item-tail::after {
    background: linear-gradient(90deg, rgb(2, 133, 127) 0%, rgb(2, 120, 214) 108.42%) !important;
}

.medimatch-steps .medimatch-steps-item-tail {
    &:after {
        width: 1.8px !important;
    }
}

.medimatch-steps .medimatch-steps-item-finish>.medimatch-steps-item-container>.medimatch-steps-item-tail::after {
    background: linear-gradient(90deg, rgb(2, 133, 127) 0%, rgb(2, 120, 214) 108.42%);

}

.ant-notification {
    .ant-notification-notice-message {
        font-family: var(--font-primary), var(--font-secondary);
        font-size: 18px;
        font-weight: 500;
    }

    .ant-notification-notice-description {
        font-family: var(--font-primary), var(--font-secondary) !important;
    }
}

.medimatch-date-panel {

    .medimatch-header-view,
    .medimatch-content {
        font-family: var(--font-primary), var(--font-secondary) !important;
    }
}

.custom-popover {
    .medimatch-popover-inner {
        padding: 4px !important;
    }
}

.modal-confirm-delete {


    .icon-bin {
        position: absolute;
        top: 0px;
        transform: translate(-50%, -50%);
        left: 50%;
    }

    .medimatch-modal-confirm-paragraph {
        max-width: 100%;
        text-align: center;
        padding-top: 35px;
    }

    .medimatch-modal-confirm-btns {
        display: flex;
    }

    .medimatch-modal-content {
        padding: 28px 36px;
    }

    .medimatch-btn-default {
        flex: 1;
        background-color: var(--ui-white) !important;
        border: 1.5px solid #E9E9E9 !important;
        color: var(--ui-gray) !important;
        font-weight: 400 !important;
        outline: none !important;
        box-shadow: none !important;

        &:hover {
            background: var(--ui-light-gray) !important;
        }

        &:disabled {
            background: var(--ui-light-gray) !important;
            color: var(--ui-gray) !important;
            filter: brightness(1);
        }
    }

    .medimatch-btn-primary {
        background-color: var(--ui-danger) !important;
        border: 1.5px solid var(--ui-danger) !important;
        color: var(--ui-white) !important;
        font-weight: 400 !important;
        outline: none !important;
        box-shadow: none !important;
        flex: 1;

        &:hover {
            background: var(--ui-danger) !important;
            filter: brightness(90%);
        }

        &:disabled {
            background: var(--ui-light-gray) !important;
            color: var(--ui-gray) !important;
            filter: brightness(1);
        }
    }

}